import React, { useContext } from 'react';
import 'antd/dist/antd.css';
import { Typography, Layout, Button, Tooltip, Row, Col, Space } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { PhoneRing } from '../../components/PhoneRing/index.js';
import WebsocketStatus from '../../components/websocket/WebSocketStatus.js';
import { AppContext } from '../index.js';
import Notification from '../../components/Notification/index.js';
import Logout from '../../function/Logout.js';

const { Header } = Layout;
const { Title } = Typography;

const Toolbar = () => {
    const context = useContext(AppContext);
    const { websocketData } = context;

    return (
        <Header style={{ backgroundColor: '#003a8c' }}>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Title
                        style={{
                            color: '#fff',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            marginLeft: -30,
                            fontWeight: 400,
                        }}
                        level={5}
                    >
                        瓦斯行管理平台
                    </Title>
                </Col>
                <Col style={{ marginTop: -15 }}>
                    <Space size='large'>
                        <WebsocketStatus websocketData={websocketData} />
                        <PhoneRing websocketData={websocketData} />
                        <Notification />
                        {/* {websocketData.topic === 'notification' && <Notice websocketData={websocketData} />} */}
                        {/* <Tooltip title='常見問題'>
                     <Button
                        style={{ backgroundColor: '#003a8c', borderColor: '#003a8c' }}
                        type='primary'
                        shape='circle'
                        icon={<QuestionOutlined />}
                     />
                  </Tooltip> */}
                        <Tooltip title='登出'>
                            <Button
                                style={{ backgroundColor: '#003a8c', borderColor: '#003a8c' }}
                                type='primary'
                                shape='circle'
                                icon={<LogoutOutlined />}
                                onClick={() => Logout()}
                            />
                        </Tooltip>
                    </Space>
                </Col>
            </Row>
        </Header>
    );
};

export default Toolbar;
