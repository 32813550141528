import React, { createContext } from 'react';
import { Button, Popover, Tooltip, Typography, Switch, Skeleton, Space } from 'antd';
import { PhoneOutlined, AlertOutlined, AlertTwoTone, DisconnectOutlined } from '@ant-design/icons';
import { PhoneNotice } from './PhoneNotice';
export const PhoneNoticeContext = createContext();
const { Text } = Typography;

export const PhoneRing = (props) => {
    const { phoneLine, SupplierPhoneLine, phoneRing } = props.websocketData;
    let state = props.websocketData.topic === 'phone-box-disconnected';
    const localphoneLine = localStorage.getItem('phoneLine')?.split(',');

    const phoneSetting =
        phoneLine && phoneRing && SupplierPhoneLine ? (
            phoneLine.map((item, index) => (
                <div key={index}>
                    <Space>
                        <Text>電話{index + 1}</Text>
                        <Switch
                            key={index}
                            onChange={(value) => props.websocketData.settingPhoneRing(value, index)}
                            checkedChildren='接收中'
                            unCheckedChildren='不接收'
                            checked={
                                localphoneLine
                                    ? localphoneLine[index] === 'true'
                                        ? true
                                        : false
                                    : false
                            }
                            disabled={
                                SupplierPhoneLine
                                    ? SupplierPhoneLine[index] === String(true)
                                        ? false
                                        : true
                                    : true
                            }
                        />
                        {phoneRing[index] === 'true' ? (
                            <AlertTwoTone key={index + 'true'} />
                        ) : (
                            <AlertOutlined key={index + 'false'} />
                        )}
                    </Space>
                    <br />
                    <br />
                </div>
            ))
        ) : (
            <Skeleton key={'Skeleton'} />
        );

    return (
        <>
            <Popover
                overlayInnerStyle={{
                    minHeight: '70%',
                    minWidth: 150,
                    marginRight: 10,
                    marginTop: 10,
                    paddingTop: 20,
                }}
                placement='bottom'
                content={phoneSetting}
                trigger='click'
            >
                <Tooltip title={`電話設定${state ? '（斷連中）' : ''}`}>
                    <Button
                        style={{
                            backgroundColor: '#003a8c',
                            borderColor: '#003a8c',
                        }}
                        type='primary'
                        shape='circle'
                        disabled={state}
                        icon={
                            state ? (
                                <DisconnectOutlined style={{ color: 'red' }} />
                            ) : (
                                <PhoneOutlined />
                            )
                        }
                    />
                </Tooltip>
            </Popover>
            <PhoneNoticeContext.Provider
                value={{
                    ring: props.websocketData.ring || {},
                    phoneCus: props.websocketData.phoneCus || {},
                }}
            >
                <PhoneNotice />
            </PhoneNoticeContext.Provider>
        </>
    );
};
