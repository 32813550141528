import './sentryInit';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/leaflet/dist/leaflet.css';
import App from './App';
import 'antd/dist/antd.less';
import './index.css';
import { ConfigProvider } from 'antd';
import zhTW from 'antd/lib/locale/zh_TW';
import 'moment/locale/zh-tw';
import HelmetData from './plugins/helmet';
import * as Sentry from '@sentry/react';

const root = ReactDOM.createRoot(document.getElementById('root'), {
    // Callback called when an error is thrown and not caught by an ErrorBoundary.
    onUncaughtError: Sentry.reactErrorHandler(),
    // Callback called when React catches an error in an ErrorBoundary.
    onCaughtError: Sentry.reactErrorHandler(),
    // Callback called when React automatically recovers from errors.
    onRecoverableError: Sentry.reactErrorHandler(),
});

root.render(
    <ConfigProvider locale={zhTW} componentSize='large'>
        <App />
        <HelmetData />
    </ConfigProvider>,
);
